import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Layout from "../components/layout"
import Head from "../components/head"
import DownloadButton from "../components/downloadButton"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 0, 4),
    },
  },
  heroGridContainer: {
    display: "grid",
    gridGap: theme.spacing(6),
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  headshot: {
    width: "80%",
  },
  heroGridItemImage: {
    textAlign: "center",
  },
  heroGridItemContent: {},
  whiteBg: {
    backgroundColor: theme.palette.background.paper,
  },
  heroButtons: {
    marginTop: theme.spacing(5),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  girdContainer: {
    display: "grid",
    gridGap: theme.spacing(4),
    gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
    marginTop: theme.spacing(3),
  },
  cardGridContainer: {
    display: "grid",
    gridGap: theme.spacing(2),
    gridTemplateColumns: "100px 1fr",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "80px 1fr",
    },
  },
  listRoot: {
    width: "100%",
  },
  heading: {
    marginTop: theme.spacing(3),
  },
}))

const AboutPage = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query HeadShotQuery {
      file(relativePath: { eq: "haruka-body-top.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head title="About" />
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <div className={classes.heroGridContainer}>
              <div className={classes.heroGridItemContent}>
                <Typography
                  component="h1"
                  variant="h4"
                  align={"left"}
                  color="textPrimary"
                  gutterBottom
                >
                  Haruka will listen to understand your needs and then
                  communicate your exact wishes to your guests in English and
                  Japanese.
                </Typography>
                <Typography
                  variant="body1"
                  align={"left"}
                  color="textSecondary"
                  paragraph
                >
                  Haruka is authentic and warm, and understands the importance
                  of intercultural communication and understanding. She cares
                  deeply about her clients and works closely with them to ensure
                  their messages are effectively conveyed at their events.
                </Typography>
                <Typography
                  variant="body1"
                  align={"left"}
                  color="textSecondary"
                  paragraph
                >
                  Haruka is a professionally trained and qualified wedding MC in
                  both English and Japanese. She’s been acting, singing, dancing
                  and performing since the age of six, and has also won several
                  English speech contests and awards in Japan over the years.
                </Typography>
                <Typography
                  variant="body1"
                  align={"left"}
                  color="textSecondary"
                  paragraph
                  gutterBottom
                >
                  Haruka is a native Japanese speaker, and developed her fluent
                  English capabilities through majoring in English at
                  university, multiple international exchange programs in high
                  school and university, and almost a decade of living and
                  working in Australia. Using her background in journalism and
                  presenting, Haruka will bring a young, fresh and articulate
                  perspective to your special event.
                </Typography>
                <DownloadButton
                  variant="contained"
                  color="primary"
                  endIcon={<PictureAsPdfIcon />}
                >
                  日本語プロフィールはこちら
                </DownloadButton>
              </div>
              <div className={classes.heroGridItemImage}>
                <Img
                  fluid={data.file.childImageSharp.fluid}
                  alt="Haruka Sinno head shot"
                  className={classes.headshot}
                />
              </div>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

export default AboutPage
